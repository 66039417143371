import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer";
import luvMain from "../assets/luvMain.png";

const LuvProtocol = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <div className="container py-5">
        <div className="d-flex align-items-center">
          <Link to="/">
            <FaArrowLeft className="fs-3" />
          </Link>
          <p className="fs-3 ms-3">$LUV Protocol</p>
        </div>

        <div className="row gx-5 mt-5 flex-wrap-reverse">
          <div className="col-12 col-md-6 col-lg-5 col-xl-4 d-flex align-content-center">
            <img
              className="w-100 m-auto mt-5 mt-md-auto"
              style={{ objectFit: "contain", maxWidth: 250 }}
              src={luvMain}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-7 col-xl-8 text-center text-md-start">
            <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
              OUR TOKEN
            </p>
            <p className="fs-1 fw-bolder">$LUV Protocol</p>
            <p className="fw-light mt-3" style={{ lineHeight: 1.8 }}>
              $LUV is a multi-utility crypto currency used in both the digital
              and physical worlds that is at the heart to the entire DeFi
              District ecosystem and is what our Cured Labs holders receive
              monthly for supporting the project in whatever way they choose to.
            </p>
            <div className='d-flex align-items-center mt-4 justify-content-center justify-content-md-start'>
              <a
                href="#"
                className="py-2 px-4 rounded-5 fw-bold text-white d-flex align-items-center"
                style={{ backgroundColor: "#E61A3D" }}
              >
                EXPLORE <FaArrowRight className="ms-2" />
              </a>
              <a className="fw-bold text-white ms-4">View Tokenomics</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LuvProtocol;
