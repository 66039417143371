import logo from "../assets/logo.png";
import { FaDiscord, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="container mt-auto">
      <div className="row py-5">
        <div className="col-4 d-flex align-items-center">
          <img width="35" src={logo} />
          <p className="fw-bold fs-4 ms-3">DeFi District</p>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-center">
          <p>© 2022 · DeFi District</p>
        </div>
        <div className="col-4 d-flex align-items-center gap-3 justify-content-end">
          <a href="https://discord.com/invite/defidistrict" target="_blank">
            <FaDiscord className="fs-4" />
          </a>
          <a href="https://twitter.com/DeFiDistrict" target="_blank">
            <FaTwitter className="fs-4" />
          </a>
          <a
          href="https://www.linkedin.com/company/83042486/admin/"
          target="_blank"
        >
          <FaLinkedin className="fs-4" />
        </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
