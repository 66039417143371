import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer";
import linkdMain from "../assets/linkdMain.png";

const Linkd = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <div className="container py-5">
        <div className="d-flex align-items-center">
          <Link to="/">
            <FaArrowLeft className="fs-3" />
          </Link>
          <p className="fs-3 ms-3">LINKD</p>
        </div>

        <div className="row gx-5 mt-5 flex-wrap-reverse">
          <div className="col-12 col-md-6 col-lg-5  d-flex align-content-center">
            <img
              className="w-100 m-auto mt-5 mt-md-auto"
              style={{ objectFit: "contain" }}
              src={linkdMain}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-7 text-center text-md-start">
            <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
              WEB3 AFFILIATES
            </p>
            <p className="fs-1 fw-bolder">LINKD</p>
            <p className="fw-light mt-3" style={{ lineHeight: 1.8 }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              quis leo non diam mattis consectetur. Quisque vel mi nec ex rutrum
              luctus tempus non lacus. Integer condimentum finibus.
            </p>
            <p className="fw-light mt-3" style={{ lineHeight: 1.8 }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              quis leo non diam mattis consectetur. Quisque vel mi nec ex rutrum
              luctus tempus non lacus. Integer condimentum finibus.Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Phasellus quis leo
              non diam mattis consectetur. Quisque vel mi nec ex rutrum luctus
              tempus non lacus. Integer condimentum finibus.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Linkd;
