import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer";
// import realisMain from "../assets/realisMain.png";
import realisMap from "../assets/realisMap.png";

const Realis = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <div className="container py-5">
        <div className="d-flex align-items-center">
          <Link to="/">
            <FaArrowLeft className="fs-3" />
          </Link>
          <p className="fs-3 ms-3">Realis</p>
        </div>

        <div className="row gx-5 mt-5 flex-wrap-reverse">
          <div className="col-12 col-md-6 col-lg-5  d-flex align-content-center">
            <img
              className="w-100"
              style={{ objectFit: "contain" }}
              // src={realisMain}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-7  text-center text-md-start">
            <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
              REAL STATE
            </p>
            <p className="fs-1 fw-bolder">Realis</p>
            <p className="fw-light mt-3" style={{ lineHeight: 1.8 }}>
              REALIS is designed as a decentralized protocol with a marketplace
              that allows you to buy, sell, and rent real estate all without a
              centralized force at the heart. Cured Labs has +66 rental
              properties already live during beta on Stride that can be booked
              in USDC, SOL, or $LUV.
            </p>
            <p className="fw-light mt-3" style={{ lineHeight: 1.8 }}>
              The app also comes with Realis Academy, which is a course created
              by real estate professionals with the core focus on teaching
              anyone in the world how to operate their own short, mid, or
              long-term rental business.
            </p>
          </div>
        </div>

        <div className="contOpacity p-4 mt-5 d-none d-sm-block" style={{borderRadius:50}}>
          <img className='w-100' src={realisMap} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Realis;
