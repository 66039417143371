import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import "./index.css";
import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import HomePage from "./pages/HomePage";
import DistrictLabs from './pages/DistrictLabs';
import Realis from './pages/Realis';
import LuvProtocol from './pages/LuvProtocol';
import LuvMarket from './pages/LuvMarket';
import Linkd from './pages/Linkd';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/DistrictLabs" element={<DistrictLabs />} />
        <Route path="/Realis" element={<Realis />} />
        <Route path="/LuvProtocol" element={<LuvProtocol />} />
        <Route path="/LuvMarket" element={<LuvMarket />} />
        <Route path="/Linkd" element={<Linkd />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
