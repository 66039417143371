import { Link } from "react-router-dom";
import defiBanner from "../assets/defiBanner.png";
import labsBanner from "../assets/labsBanner.png";
import realisBanner from "../assets/realisBanner.png";
import luvBanner from "../assets/luvBanner.png";
import luvBanner1 from "../assets/luvBanner1.png";
import distuBanner from "../assets/distuBanner.png";
import luvMarketBanner from "../assets/luvMarketBanner.png";
import luv from "../assets/luvMain.png";
import linkdBanner from "../assets/linkdBanner.png";
import animation from "../assets/anim5.gif";
import Footer from "../components/Footer";
import { FaDiscord, FaTwitter, FaLinkedin } from "react-icons/fa";

const HomePage = () => {
  return (
    <div className="container">
      {/* ICONS */}

      <div className="d-flex align-items-center gap-3 justify-content-end mt-4">
        <a href="https://discord.com/invite/defidistrict" target="_blank">
          <FaDiscord className="fs-4" />
        </a>
        <a href="https://twitter.com/DeFiDistrict" target="_blank">
          <FaTwitter className="fs-4" />
        </a>
        <a
          href="https://www.linkedin.com/company/83042486/admin/"
          target="_blank"
        >
          <FaLinkedin className="fs-4" />
        </a>
      </div>

      {/* HEAD SECTION */}
      <div className="mainHome d-flex align-items-center justify-content-center my-5">
        <div className="text-center">
          <p className="fs-1">
            The <span className="gradientText fw-bold">DeFi District</span>{" "}
            <br /> Ecosystem
          </p>
          <p className="w-75 m-auto mt-3">
            DeFi District is a phygital community of businesses built by
            District Labs (formerly Cur(s)ed Labs) that resides in both physical
            and digital worlds.
          </p>
        </div>
      </div>
      {/* END HEAD SECTION */}

      {/* PROJECT SECTION */}
      <div className="row g-4 gy-5">
        <a className="col-12">
          <div className=" containerProject h-100">
            <img className="imgProject w-100" src={defiBanner} />
            <div className="py-4 px-4 row">
              <div className="col-11 align-self-center">
                <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
                  DIGITAL GROUP
                </p>
                <p className="fs-4 fw-bolder">District Labs</p>
              </div>
              <div className="col-1"></div>
              <div className="col-12 mt-3">
                <p className="fw-light">
                  The founders come from a real estate, web development,
                  hospitality, and marketing background. Their goal within the
                  space is to merge blockchain utilities with real world use
                  cases while at the same time, offer other aligning projects
                  support through DeFi Districts suite of creative products and
                  services.
                </p>
              </div>
            </div>
          </div>
        </a>

        {/* DISTRIC LABS */}
        <a
          href="https://magiceden.io/marketplace/curedlabs"
          target="_blank"
          className="col-12 col-md-6"
        >
          <div className=" containerProject h-100">
            <img className="imgProject w-100" src={labsBanner} />
            <div className="py-4 px-4 row">
              <div className="col-6 align-self-center">
                <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
                  COLLECTION
                </p>
                <p className="fs-4 fw-bolder">District Labs</p>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end">
                <p className="fw-light letraPeq">SHOP NOW</p>
                <p
                  className="ms-3 py-1 px-2 rounded-4 letraPeq fw-bold"
                  style={{ backgroundColor: "#E61A3D" }}
                >
                  5.300 NFTs
                </p>
              </div>
              <div className="col-12 mt-3">
                <p className="fw-light">
                  District Labs is a deflationary collection that sold out in an
                  hour via stealth mint in March 2022 with no launchpad, no
                  influencers, and no bots. District Labs are the foundation to
                  Defi District.
                </p>
              </div>
            </div>
          </div>
        </a>

        {/* REALIS */}
        <a className="col-12 col-md-6">
          <div className=" containerProject h-100">
            <img className="imgProject w-100" src={realisBanner} />
            <div className="py-4 px-4 row">
              <div className="col-6 align-self-center">
                <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
                  REAL STATE
                </p>
                <p className="fs-4 fw-bolder">Realis</p>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end">
                <p
                  className="ms-3 py-1 px-2 rounded-4 letraPeq fw-bold"
                  style={{ backgroundColor: "#E61A3D" }}
                >
                  +90 rentals
                </p>
              </div>
              <div className="col-12 mt-3">
                <p className="fw-light">
                  REALIS is designed as a decentralized protocol with a
                  marketplace that allows you to buy, sell, and rent real estate
                  all without a centralized force at the heart.
                </p>
              </div>
            </div>
          </div>
        </a>

        {/* LUV */}
        <a
          href="https://luvtokenomics.com/"
          target="_blank"
          className="col-12 d-grid"
        >
          <div className="h-100 luv">
            <img
              className="imgProject w-100"
              style={{ objectFit: "contain", borderRadius: 40,objectFit:'cover',height:165 }}
              src={luvBanner1}
            />
          </div>
          <div className="py-0 px-4 d-flex align-items-center justify-content-between luv">
            <div>
              <p className="letraPeq">TOKENS</p>
              <p className="fw-bold fs-3">$LUV Tokenomics</p>
              <p>The heart to the entire DeFi District Ecosystem.</p>
            </div>
            <div className="d-none d-md-flex">
              <img style={{width:95}} src={luv}/>
            </div>
          </div>
        </a>

        {/* DISTU */}
        <a href="https://distu.xyz" target="_blank" className="col-12">
          <div className=" containerProject h-100">
            <img className="imgProject w-100" src={animation} />
            <div className="py-4 px-4 row">
              <div className="col-11 align-self-center">
                <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
                  WEB3 AGENCY
                </p>
                <p className="fs-4 fw-bolder">Distortion Studios</p>
              </div>
              <div className="col-1"></div>
              <div className="col-12 mt-3">
                <p className="fw-light">
                  Distu is our in-house branding, animation, and web development
                  studio that services DeFi District and a portfolio of web2 and
                  web3 clients. The creative studio helps businesses create
                  beautiful illustrations for magnificent marketing campaigns
                  that will also help you tell the perfect story.
                </p>
              </div>
            </div>
          </div>
        </a>

        {/* LUV MARKET */}
        <a
          href="https://luv.market/"
          target="_blank"
          className="col-12 col-md-6"
        >
          <div className=" containerProject h-100">
            <img className="imgProject w-100" src={luvMarketBanner} />
            <div className="py-4 px-4 row">
              <div className="col-6 align-self-center">
                <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
                  PHYGITAL MARKETPLACE
                </p>
                <p className="fs-4 fw-bolder">$LUV Market</p>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end">
                <p className="fw-light letraPeq">SHOP NOW</p>
                <p
                  className="ms-3 py-1 px-2 rounded-4 letraPeq fw-bold"
                  style={{ backgroundColor: "#E61A3D" }}
                >
                  5.555 NFTs
                </p>
              </div>
              <div className="col-12 mt-3">
                <p className="fw-light">
                  The Decentralized e-commerce and freelance marketplace. Think
                  Upwork and E-Bay meets web3. Users can become verified Luv
                  Merchants giving them access to sell phygital products or
                  services such as; Event tickets, consulting, NFT’s,
                  white-lists, gift cards, and even vacations.
                </p>
              </div>
            </div>
          </div>
        </a>

        {/* LINKD */}
        <a className="col-12 col-md-6">
          <div className=" containerProject h-100">
            <img className="imgProject w-100" src={linkdBanner} />
            <div className="py-4 px-4 row">
              <div className="col-6 align-self-center">
                <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
                  WEB3 AFFILIATES
                </p>
                <p className="fs-4 fw-bolder">LINKD</p>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end">
                <p
                  className="ms-3 py-1 px-2 rounded-4 letraPeq fw-bold"
                  style={{ backgroundColor: "#E61A3D" }}
                >
                  LEARN MORE
                </p>
              </div>
              <div className="col-12 mt-3">
                <p className="fw-light">
                  An e-commerce affiliate based marketing hub specializing in
                  data tools and analytics. LinkD heavily emphasizes on
                  interactive learning and community. We aim to be a key
                  contributor in breaking down the walls between web2 and web3
                  for individual as well as corporate consumers.
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
      {/* END PROJECT SECTION */}

      <Footer />
    </div>
  );
};

export default HomePage;
