import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer";
import defiDistrictMain from "../assets/defiDistrictMain.png";

const DistrictLabs = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <div className="container py-5">
        <div className="d-flex align-items-center">
          <Link to="/">
            <FaArrowLeft className="fs-3" />
          </Link>
          <p className="fs-3 ms-3">District Labs</p>
        </div>

        <div className="row gx-5 mt-5 flex-wrap-reverse">
          <div className="col-12 col-md-6 col-lg-5 col-xl-4 d-flex align-content-center">
            <img
              className="w-100"
              style={{ objectFit: "contain" }}
              src={defiDistrictMain}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-7 col-xl-8 text-center text-md-start">
            <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
              DIGITAL GROUP
            </p>
            <p className="fs-1 fw-bolder">District Labs</p>
            <p className="fw-light mt-3" style={{ lineHeight: 1.8 }}>
              District Labs is a deflationary collection of 5,300 NFT’s that
              sold out in an hour via stealth mint in March 2022 with no
              launchpad, no influencers, and no bots. District Labs are the main
              collection and foundation to Defi District. All businesses within
              the Defi Ecosystem benefit District Labs in the form of monthly
              $LUV rewards that can be claimed at the 1st of every month.
            </p>
            <p className="fw-light mt-3" style={{ lineHeight: 1.8 }}>
              There’s no staking required for your District NFT. Holders must
              simply have their Labs delisted during the time of the random
              monthly snapshot to be eligible for the following months $LUV
              claim. “Cursed” District Labs get 25 $LUV, while “Cured” District
              Labs get 100 $LUV per month.
            </p>
          </div>
        </div>

        <div className="contOpacity py-4 px-4 px-md-5 rounded-5 mt-5 d-flex justify-content-between align-items-center flex-wrap">
          <div className=" align-self-center m-auto m-sm-0">
            <p className="fw-bold letraPeq" style={{ color: "#E61A3D" }}>
              MAIN COLLECTION
            </p>
            <p className="fs-4 fw-bolder">District Labs</p>
          </div>

          <div className="d-flex align-items-center m-auto m-sm-0 mt-3 mt-sm-0">
            <div>
              <p className="fs-3 fw-bold opacity-50">5.300</p>
              <p className="letraPeq fw-bold opacity-50">NFTs</p>
            </div>
            <div>
              <a
                href="#"
                className="ms-4 py-2 px-4 rounded-5 fw-bold text-white d-flex align-items-center"
                style={{ backgroundColor: "#E61A3D" }}
              >
                EXPLORE <FaArrowRight className="ms-2" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DistrictLabs;
